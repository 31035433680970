var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"input",class:{
      'input--filled': !!_vm.value,
      'input--error': _vm.error.length,
      'input--icon-left': _vm.iconPosition === 'left',
      'input--icon-right': _vm.iconPosition === 'right',
      'input--disabled': _vm.disabled,
    }},[(_vm.title)?_c('span',{staticClass:"input__title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('span',{staticClass:"input__container"},[(_vm.iconPosition)?_c('button',{staticClass:"input__action",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('action')}}},[_vm._t("icon",function(){return [_c('IconComponent',{attrs:{"name":"search"}})]})],2):_vm._e(),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],ref:"input",staticClass:"input__field",attrs:{"disabled":_vm.disabled,"max":_vm.max,"min":_vm.min,"placeholder":_vm.placeholder,"type":_vm.type,"required":_vm.required,"autofocus":_vm.autofocus},domProps:{"value":_vm.value},on:{"blur":function($event){return _vm.$emit('blur', $event)},"focus":function($event){return _vm.$emit('focus', $event)},"input":_vm.handleInput,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('enter', $event)}}})]),_c('ul',{staticClass:"input__error"},_vm._l((_vm.error),function(e,i){return _c('li',{key:i},[_vm._v(_vm._s(e))])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }